import PropTypes from 'prop-types';
import cn from 'classnames';

import StarIcon from 'assets/icons/StarIcon';
import LoadingIcon from 'assets/icons/LoadingIcon';
import { useState, useRef } from 'react';
import { ArrowRightIcon } from '../../assets/icons/ArrowRightIcon';
import useClickOutside from '../../hooks/useClickOutside';

const ButtonStar = ({
  active,
  options,
  isLoading,
  label,
  selected,
  onChange,
  expandClassname,
  listsClassname,
}) => {
  const listOfActive = options.filter(({ id }) => selected.find((el) => el.id === id));
  const listOfNonActive = options.filter(({ id }) => !selected.find((el) => el.id === id));
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedType, setSelectedType] = useState(0);
  const ref = useRef();
  const selectRef = useRef();
  const handleExpand = () => {
    const cell = ref.current.closest('.ag-cell');
    if (cell) {
      cell.style.overflow = 'visible';
    }
    const row = ref.current.closest('.ag-row');
    if (row) {
      row.style.overflow = 'visible';
      row.style.zIndex = '25';
    }
    setIsExpanded(true);
  };
  const handleClose = () => {
    setIsExpanded(false);
    setSelectedType(0);
    const cell = ref.current.closest('.ag-cell');
    cell.style.overflow = '';
    const row = ref.current.closest('.ag-row');
    row.style.overflow = '';
    row.style.zIndex = '';
  };
  useClickOutside(selectRef, () => {
    if (isExpanded) {
      handleClose();
    }
  });
  return (
    <div className="group flex items-center justify-center p-1 relative overflow-visible" ref={ref}>
      {label && <p className="font-normal text-sm font-SofiaPro mr-1">{label}</p>}

      {isLoading ? (
        <LoadingIcon className="h-3.5 w-3.5 flex-none animate-spin text-yellow-bright self-center" />
      ) : (
        <div className="relative flex items-center" ref={selectRef}>
          <button onClick={handleExpand}>
            <StarIcon
              className={cn('transition h-3.5 w-3.5 pointer-events-none', {
                ['text-yellow-bright']: active,
                ['text-gray-250 group-hover:text-gray-400']: !active,
              })}
            />
          </button>
          {isExpanded && (
            <div
              className={cn('absolute rounded-[6px] w-[227px] p-[5px] top-[20px]', {
                [expandClassname]: expandClassname,
              })}
              style={{
                backgroundColor: 'rgba(246, 246, 246)',
                zIndex: 26,
                border: '1px solid rgba(0, 0, 0, 0.4)',
              }}
            >
              {[
                { value: 1, label: 'Удалить из избранного' },
                { value: 2, label: 'Добавить в избранное' },
              ]
                .filter((el, index) => !(index === 0 && !active))
                .map(({ value, label }) => (
                  <div
                    key={value}
                    className={cn(
                      'text-black flex justify-between items-center h-[22px] px-2.5 font-medium hover:text-[#219653] rounded-[5px] cursor-pointer',
                      {
                        'text-[#219653]': selectedType === value,
                      },
                    )}
                    onClick={() => setSelectedType(value)}
                  >
                    {label}
                    <ArrowRightIcon />
                  </div>
                ))}
              {selectedType !== 0 &&
                (selectedType === 1 ? listOfActive : listOfNonActive).length !== 0 && (
                  <div
                    className={cn('absolute rounded-[6px] w-fit p-[5px]', {
                      'top-0': selectedType === 1,
                      'top-[24px]': selectedType === 2,
                      [listsClassname]: listsClassname,
                      'left-[224px]': !listsClassname,
                    })}
                    style={{
                      backgroundColor: 'rgba(246, 246, 246)',
                      zIndex: 26,
                      border: '1px solid rgba(0, 0, 0, 0.4)',
                    }}
                  >
                    {(selectedType === 1 ? listOfActive : listOfNonActive).map(({ id, name }) => (
                      <div
                        key={id}
                        className={cn(
                          'text-black flex justify-between items-center h-[22px] px-2.5 font-medium hover:text-[#219653] rounded-[5px] cursor-pointer whitespace-nowrap',
                        )}
                        onClick={() => {
                          onChange(id, selectedType === 1 ? 'remove' : 'add');
                          handleClose();
                        }}
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ButtonStar.propTypes = {
  onChange: PropTypes.func,
  expandClassname: PropTypes.string,
  listsClassname: PropTypes.string,
  active: PropTypes.bool,
  isLoading: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.array,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};
export default ButtonStar;
