import PropTypes from 'prop-types';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import {
  ActivationLayout,
  DefaultLayout,
  TableMainLayout,
  ProductLayout,
  ProfileLayout,
  TableInnerLayout,
  TableGrowthLayout,
} from './Layout';
import Header from './Header';

import {
  ApiKeys,
  AdvertisingRates,
  Brands,
  Categories,
  Dynamic,
  Products,
  SalesByDay,
  Sellers,
  Pricing,
  FAQ,
  ActivateDemo,
  ActivateFullAccess,
  ActivateDemonstration,
  ErrorAccess,
  Warehouses,
  PriceSegments,
  Subscriptions,
  Profile,
  Tariffs,
  SuccessPayment,
  SuccessPaymentUL,
  SEO,
  Referrals,
  GrowthProducts,
  GrowthCategories,
  GrowthBrands,
  GrowthSellers,
  InnerProducts,
  Positions,
  InnerInDays,
  InnerSales,
  InnerWeeks,
  InnerProduct,
  InnerInDays2,
  InnerPrices,
  InnerWarehouses,
  InnerDelivery,
  SEOCompare,
  SEOHints,
  SeoSmart,
  InnerProductsSettings,
  SEOSmartDescription,
  Navigation,
} from 'pages';

import Notification from 'components/Notification';
import {
  PAGE_ADVERTISING_RATES,
  PAGE_BRANDS,
  PAGE_TRENDS,
  PAGE_MAIN,
  PAGE_PRICE_SEGMENTS,
  PAGE_PRODUCTS,
  PAGE_SALES,
  PAGE_SELLERS,
  PAGE_WAREHOUSES,
  PAGE_PROFILE,
  PAGE_PROFILE_SUBSCRIPTIONS,
  PAGE_TARIFFS,
  PAGE_PROFILE_TARIFFS,
  PAGE_SUCCESS_PAYMENT,
  PAGE_SUCCESS_PAYMENT_UL,
  PAGE_PROFILE_REFERRALS,
  PAGE_SIGNUP,
  PAGE_SEO,
  PAGE_GROWTH_PRODUCTS,
  PAGE_GROWTH,
  PAGE_GROWTH_SELLERS,
  PAGE_GROWTH_BRANDS,
  PAGE_API_KEYS,
  PAGE_POSITIONS,
  PAGE_INNER,
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_IN_DAYS,
  PAGE_INNER_SALES,
  PAGE_INNER_PRODUCT_ID,
  PAGE_PRODUCT_ID,
  PAGE_INNER_WEEKS,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_PRICES_TAB,
  PAGE_INNER_WAREHOUSES,
  PAGE_INNER_DELIVERY_TAB,
  PAGE_INNER_PRODUCTS_SETTINGS,
  PAGE_NAVIGATION,
} from 'constants/pages';

import MobileInterface from './MobileInterface';
import {
  BY_PRODUCTS,
  PAGE_SEO_BY_PHRASE,
  PAGE_SEO_HINTS,
  PAGE_SEO_MONITORING,
  PAGE_SEO_SMART,
  PAGE_SEO_SMART_DESCRIPTION,
} from 'constants/seo';
import { useLocationPath } from 'hooks/useLocationPath';
import DynamicLayout from 'components/Layout/DynamicLayout';
import Footer from 'components/Footer';
import InterestingTextModal from 'components/InterestingTextModal';
import { PAGE_SEO_COMPARE } from 'constants';
import { SEOMonitoring, SEOByPhrase } from '../pages/SEO';
import VideoLessonsModal from './VideoLessonsModal';
import { useAuth } from '../context/auth.context';

const TableRoutes = () => (
  <TableMainLayout>
    <Switch>
      <Route path={PAGE_MAIN} component={Categories} exact />
      <Route path={PAGE_BRANDS} component={Brands} />
      <Route path={PAGE_PRODUCTS} component={Products} />
      <Route path={PAGE_SALES} component={SalesByDay} />
      <Route path={PAGE_SELLERS} component={Sellers} />
      <Route path={PAGE_WAREHOUSES} component={Warehouses} />
      <Route path={PAGE_PRICE_SEGMENTS} component={PriceSegments} />
      <DynamicLayout>
        <Route path={PAGE_TRENDS} component={Dynamic} />
      </DynamicLayout>
    </Switch>
  </TableMainLayout>
);

const TableGrowthRoutes = () => (
  <TableGrowthLayout>
    <Switch>
      <Route path={PAGE_GROWTH} component={GrowthCategories} exact />
      <Route path={PAGE_GROWTH_SELLERS} component={GrowthSellers} />
      <Route path={PAGE_GROWTH_BRANDS} component={GrowthBrands} />
      <Route path={PAGE_GROWTH_PRODUCTS} component={GrowthProducts} />
    </Switch>
  </TableGrowthLayout>
);

export const ActivationRoutes = () => (
  <ActivationLayout>
    <Switch>
      <Route path="/activate-account/full-access" component={ActivateFullAccess} exact />
      <Route path="/activate-account/demonstration" component={ActivateDemonstration} exact />
    </Switch>
  </ActivationLayout>
);

export const ProfileRoutes = () => {
  const { isAdmin } = useAuth();
  return (
    <ProfileLayout>
      <Switch>
        <Route path={PAGE_PROFILE_REFERRALS} component={Referrals} title="Реферальная программа" />
        {isAdmin && <Route path={PAGE_PROFILE_TARIFFS} component={Tariffs} title="Тарифы" />}
        <Route path={PAGE_PROFILE_SUBSCRIPTIONS} component={Subscriptions} />
        <Route path={PAGE_PROFILE} component={Profile} />
      </Switch>
    </ProfileLayout>
  );
};

const InnerRoutes = () => {
  return (
    <TableInnerLayout>
      <Switch>
        <Route path={PAGE_INNER_PRODUCTS} component={InnerProducts} />
        <Route path={PAGE_INNER_IN_DAYS} component={InnerInDays} />
        <Route path={PAGE_INNER_SALES} component={InnerSales} />
        <Route path={PAGE_INNER_WEEKS} component={InnerWeeks} />
        <Route
          path={[`${PAGE_INNER_PRODUCT_ID}/:tab`, PAGE_INNER_PRODUCT_ID]}
          component={InnerProduct}
        />
        <Route path={PAGE_INNER_IN_DAYS_2} component={InnerInDays2} />
        <Route path={PAGE_INNER_WAREHOUSES} component={InnerWarehouses} />
      </Switch>
    </TableInnerLayout>
  );
};

const DefaultRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <DefaultLayout>
        <Component {...props} />
      </DefaultLayout>
    )}
  />
);
DefaultRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

const NotFound = () => <h1>Nothing Found</h1>;

const PublicLayout = () => (
  <>
    <Route component={ActivationRoutes} />
  </>
);

const ProtectedLayout = () => {
  useLocationPath();
  const { isBasic } = useAuth();
  return (
    <>
      <Header />
      <Notification />
      <Switch>
        <Route path={PAGE_MAIN} component={TableRoutes} />

        <Route path={PAGE_GROWTH} component={TableGrowthRoutes} />

        <Route path={PAGE_API_KEYS} component={ApiKeys} />
        <Route path={PAGE_NAVIGATION} component={Navigation} />
        <Route path={[`${PAGE_PRODUCT_ID}/:tab`, PAGE_PRODUCT_ID]} component={ProductLayout} />
        <DefaultRoute path="/faq" component={FAQ} />

        <Route path={PAGE_ADVERTISING_RATES} component={AdvertisingRates} />
        <Route path={PAGE_PROFILE} component={ProfileRoutes} />

        <Route path={PAGE_POSITIONS} component={Positions} />

        <DefaultRoute path={PAGE_TARIFFS} component={Tariffs} />
        <DefaultRoute path={PAGE_SUCCESS_PAYMENT} component={SuccessPayment} />
        <DefaultRoute path={PAGE_SUCCESS_PAYMENT_UL} component={SuccessPaymentUL} />

        <Route path="/sign-in">
          <Redirect to={PAGE_MAIN} />
        </Route>
        <Route path="/recovery-password">
          <Redirect to={PAGE_MAIN} />
        </Route>
        <Route path="/reset-password">
          <Redirect to={PAGE_MAIN} />
        </Route>
        <Route path={PAGE_SIGNUP}>
          <Redirect to={PAGE_MAIN} />
        </Route>
        <Route exact path="/">
          <Redirect to={PAGE_MAIN} />
        </Route>

        <Route exact path="/activate-demo/:tariffId/:token" component={ActivateDemo} />

        {!isBasic && <Route path={PAGE_INNER_PRICES_TAB} component={InnerPrices} />}
        {!isBasic && (
          <Route path={PAGE_INNER_PRODUCTS_SETTINGS} component={InnerProductsSettings} />
        )}
        {!isBasic && <Route path={PAGE_INNER_DELIVERY_TAB} component={InnerDelivery} />}
        {!isBasic && <Route path={PAGE_INNER} component={InnerRoutes} />}
        {!isBasic && <Route path={PAGE_SEO_COMPARE} component={SEOCompare} />}
        {!isBasic && <Route path={PAGE_SEO_MONITORING} component={SEOMonitoring} />}
        {!isBasic && <Route path={PAGE_SEO_BY_PHRASE} component={SEOByPhrase} />}
        {!isBasic && <Route path={PAGE_SEO_HINTS} component={SEOHints} />}
        {!isBasic && <Route path={PAGE_SEO_SMART} component={SeoSmart} />}
        {!isBasic && <Route path={PAGE_SEO_SMART_DESCRIPTION} component={SEOSmartDescription} />}
        {!isBasic && <Route path={[`${PAGE_SEO}/:by/:tab`, `${PAGE_SEO}/:by`]} component={SEO} />}
        {!isBasic && (
          <Route path={PAGE_SEO}>
            <Redirect to={`${PAGE_SEO}/${BY_PRODUCTS}`} />
          </Route>
        )}
        {!isBasic && <DefaultRoute path="/pricing" component={Pricing} />}
        <Route path="**" component={NotFound} />
      </Switch>
      <MobileInterface />
      <InterestingTextModal />
      <VideoLessonsModal />
    </>
  );
};

const AuthenticatedApp = () => {
  return (
    <div className="flex flex-col items-stretch min-h-screen">
      <Switch>
        <Route path="/activate-account" component={PublicLayout} />
        <Route path="/error-access" component={ErrorAccess} />
        <Route path="/" component={ProtectedLayout} />
      </Switch>
      <Footer />
    </div>
  );
};

export default withRouter(AuthenticatedApp);
