import moment from 'moment';

import {
  TARIFF_ADMIN,
  TARIFF_BASIC,
  TARIFF_EXPERT,
  TARIFF_PRO,
  TARIFF_TRIAL,
} from 'constants/filter';

import {
  isInnerTablePage,
  isProductPage,
  isSeoByPhrasePage,
  isSeoPage,
  isTrendsPage,
} from 'utils/pages';
import {
  PAGE_API_KEYS,
  PAGE_GROWTH,
  PAGE_INNER_IN_DAYS_2,
  PAGE_INNER_PRODUCT,
  PAGE_INNER_PRODUCTS,
  PAGE_INNER_SALES,
  PAGE_PRICE_SEGMENTS,
  PAGE_PRODUCT,
  PAGE_PRODUCT_END_COLORS,
  PAGE_PRODUCT_END_SEARCH,
  PAGE_PRODUCT_END_SIZES,
  PAGE_PRODUCT_END_STOCKS,
  PAGE_SALES,
  PAGE_SEO,
  PAGE_TRENDS,
  PAGE_PRODUCT_END_ORDERS_SALES,
  PAGE_INNER_WEEKS,
  PAGE_WAREHOUSES,
  PAGE_SELLERS,
  PAGE_BRANDS,
} from 'constants/pages';
import { COLUMNS, PRODUCT_SORT, TABLE_TYPE } from 'constants/table';
import { isPageWithNewAdvertising } from 'helpers/filter';
import {
  F,
  PAGE_INNER_DELIVERY_TAB,
  PAGE_INNER_PRICES_TAB,
  PAGE_MAIN,
  PAGE_PRODUCTS,
  PAGE_SEO_BY_PHRASE,
  PAGE_SEO_MONITORING,
  PERIOD_DAY,
  PERIOD_WEEK,
} from 'constants';
import { TABLE_HEADER_AVAILABLE_PERIODS } from 'components/TableHeaderPeriodControls/TableHeaderPeriodControls';

const DATE_FORMAT = 'YYYY-MM-DD';

const WAREHOUSE_FBO_WB = 41;
const WAREHOUSE_FBO_OZON = 12;
const WAREHOUSE_FBS_WB = 45;
const WAREHOUSE_FBS_OZON = 13;
const WAREHOUSE_TYPE_FBS = 'fbs';
const WAREHOUSE_TYPE_FBO = 'fbo';
const PLATFORM_OZON = '1';
const PLATFORM_WB = '2';
const DEFAULT_PLATFORMS = [{ id: PLATFORM_WB, name: 'wb' }];

const RANGE_DEFAULT = 14;
const RANGE_DEFAULT_TRENDS = 30;
const RANGE_DEFAULT_TRENDS_PRO = 180;
// const RANGE_DEFAULT_TRIAL = 7;
const RANGE_DEFAULT_INNER_ANALYTICS = 30;

const RANGE_AVAILABLE_PRODUCT = 30;

const DATE_STEP_BY_TARIFF = (/*{ isTrends, isProduct }*/) => ({
  [TARIFF_BASIC]: 30,
  [TARIFF_EXPERT]: 30,
  [TARIFF_PRO]: 90,
  [TARIFF_TRIAL]: 90,
  [TARIFF_ADMIN]: 365 * 10,
});

const DATE_STEP_INNER = 365 * 10;

const DATE_RANGE_BY_TARIFF = {
  [TARIFF_BASIC]: 720,
  [TARIFF_EXPERT]: 30,
  [TARIFF_PRO]: 720,
  [TARIFF_TRIAL]: 720,
  [TARIFF_ADMIN]: 365 * 10,
};

const DATE_RANGE_INNER = 365 * 10;

const INITIAL_DATE = [
  moment().subtract(RANGE_DEFAULT, 'days').format(DATE_FORMAT),
  moment().subtract(1, 'days').format(DATE_FORMAT),
];

const INITIAL_DATE_PRODUCT = [
  moment().subtract(RANGE_AVAILABLE_PRODUCT, 'days').format(DATE_FORMAT),
  moment().subtract(1, 'days').format(DATE_FORMAT),
];

const getPrevWeekDates = () => {
  const formatDate = (date) => {
    let dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    let mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    let yy = date.getFullYear();

    return yy + '-' + mm + '-' + dd;
  };
  for (let i = 0; ; i++) {
    const checkDate = new Date(new Date().setDate(new Date().getDate() - i));
    if (i >= 7 && checkDate.getDay() === 1) {
      const endDate = new Date(new Date().setDate(new Date().getDate() - (i - 6)));
      return [formatDate(checkDate), formatDate(endDate)];
    }
  }
};

// const INITIAL_DATE_TRIAL = [
//   moment().subtract(RANGE_DEFAULT_TRIAL + 7, 'days').format(DATE_FORMAT),
//   moment().subtract(7, 'days').format(DATE_FORMAT),
// ];

const INITIAL_DATE_DEMO = [
  moment('20221229').startOf('day').format(DATE_FORMAT),
  moment('20230102').startOf('day').format(DATE_FORMAT),
];

const INITIAL_PRO_TRENDS_DATE = [
  moment().subtract(RANGE_DEFAULT_TRENDS_PRO, 'days').startOf('day').format(DATE_FORMAT),
  moment().subtract(1, 'days').startOf('day').format(DATE_FORMAT),
];

const INITIAL_TRENDS_DATE = [
  moment().subtract(RANGE_DEFAULT_TRENDS, 'days').startOf('day').format(DATE_FORMAT),
  moment().subtract(1, 'days').startOf('day').format(DATE_FORMAT),
];

const INITIAL_INNER_ANALYTICS_DATE = [
  moment().subtract(RANGE_DEFAULT_INNER_ANALYTICS, 'days').startOf('day').format(DATE_FORMAT),
  moment().subtract(1, 'days').startOf('day').format(DATE_FORMAT),
];

const TODAY = moment().startOf('day');
const YESTERDAY = moment().subtract(1, 'days').startOf('day');
const AGO = moment().subtract(10, 'year').startOf('day');

const getTariffRange = ({ tariffName, isTrends, isProduct }) => {
  const isInner = isInnerTablePage();

  if (isInner) {
    return {
      step: DATE_STEP_INNER,
      range: DATE_RANGE_INNER,
    };
  }

  return {
    step: DATE_STEP_BY_TARIFF({ isTrends, isProduct })?.[tariffName] || null,
    range: DATE_RANGE_BY_TARIFF?.[tariffName] || null,
  };
};

const getDisabledDaysBefore = () => {
  return {
    [TARIFF_BASIC]: moment()
      .subtract(DATE_RANGE_BY_TARIFF[TARIFF_BASIC], 'days')
      .startOf('day')
      .toDate(),
    [TARIFF_EXPERT]: moment()
      .subtract(DATE_RANGE_BY_TARIFF[TARIFF_EXPERT], 'days')
      .startOf('day')
      .toDate(),
    [TARIFF_PRO]: moment()
      .subtract(DATE_RANGE_BY_TARIFF[TARIFF_PRO], 'days')
      .startOf('day')
      .toDate(),
    [TARIFF_TRIAL]: moment()
      .subtract(DATE_RANGE_BY_TARIFF[TARIFF_TRIAL], 'days')
      .startOf('day')
      .toDate(),
    [TARIFF_ADMIN]: AGO.toDate(),
  };
};

const getDisabledDaysAfter = () => {
  return {
    [TARIFF_BASIC]: YESTERDAY.toDate(),
    [TARIFF_EXPERT]: YESTERDAY.toDate(),
    [TARIFF_PRO]: YESTERDAY.toDate(),
    [TARIFF_TRIAL]: YESTERDAY.toDate(),
    [TARIFF_ADMIN]: TODAY.toDate(),
  };
};

const DAYS_BEFORE_INNER = AGO.toDate();
const DAYS_AFTER_INNER = TODAY.toDate();

const isOzonPlatform = (id) => +id === +PLATFORM_OZON;
const isWbPlatform = (id) => +id === +PLATFORM_WB;

const DEFAULT_PLATFORM = PLATFORM_WB;

const getInitialPlatform = (platforms) => {
  if (!platforms || !platforms.length) {
    return DEFAULT_PLATFORM;
  }

  const ids = platforms.map((platform) => `${platform?.id}`);

  if (ids.includes(DEFAULT_PLATFORM)) {
    return DEFAULT_PLATFORM;
  }

  return ids[0];
};

const getDateInitial = ({ tariffName, isTrends, isInner }) => {
  const isProduct = isProductPage();
  if (isInner) {
    return INITIAL_INNER_ANALYTICS_DATE;
  }

  if (!tariffName || tariffName === 'Расширение WB') {
    return INITIAL_DATE_DEMO;
  }

  if (
    window.location.pathname.includes(PAGE_SEO_MONITORING) ||
    window.location.pathname.includes(PAGE_SEO_BY_PHRASE)
  ) {
    return INITIAL_TRENDS_DATE;
  }

  if (tariffName === TARIFF_TRIAL) {
    return /*INITIAL_DATE_TRIAL*/ getPrevWeekDates();
  }

  if (isTrends) {
    return tariffName === TARIFF_PRO ? INITIAL_PRO_TRENDS_DATE : INITIAL_TRENDS_DATE;
  }

  if (isInner) {
    return INITIAL_INNER_ANALYTICS_DATE;
  }

  return isProduct ? INITIAL_DATE_PRODUCT : INITIAL_DATE;
};

const getDateInitialProduct = ({ tariffName }) => {
  if (!tariffName || tariffName === 'Расширение WB') {
    return INITIAL_DATE_DEMO;
  }

  if (tariffName === TARIFF_TRIAL) {
    return /*INITIAL_DATE_TRIAL*/ getPrevWeekDates();
  }

  return INITIAL_DATE_PRODUCT;
};

const getDateBeforeAfter = ({ tariffName }) => {
  const isInner = isInnerTablePage();

  if (isInner) {
    return [DAYS_BEFORE_INNER, DAYS_AFTER_INNER];
  }

  const DISABLED_DAYS_BEFORE = getDisabledDaysBefore();
  const DISABLED_DAYS_AFTER = getDisabledDaysAfter();

  return [DISABLED_DAYS_BEFORE[tariffName], DISABLED_DAYS_AFTER[tariffName]];
};

const checkDateRange = ({ date, tariffName, isInner }) => {
  if (isInner) return true;

  const currentDate = date;

  if (!currentDate) return false;

  const [DISABLED_DAYS_BEFORE, DISABLED_DAYS_AFTER] = getDateBeforeAfter({ tariffName });

  const startDate = moment(currentDate[0]).endOf('day');
  const endDate = moment(currentDate[1]).endOf('day');
  const possibleStartDate = moment(DISABLED_DAYS_BEFORE).endOf('day');
  const possibleEndDate = moment(DISABLED_DAYS_AFTER).endOf('day');

  const diffStart = startDate.diff(possibleStartDate, 'days');
  const diffEnd = endDate.diff(possibleEndDate, 'days');

  return diffStart >= 0 && diffEnd <= 0;
};

const getCorrectDateStart = ({ date, step }) => {
  return moment(date[1])
    .subtract(step - 1, 'days')
    .startOf('day')
    .toDate();
};

const getCorrectDateEnd = ({ date, step }) => {
  return moment(date[0])
    .add(step - 1, 'days')
    .startOf('day')
    .toDate();
};

const getStep = ({ daysRange, isTrends, isProduct, tariffName }) => {
  if (daysRange) {
    return daysRange;
  }

  const isInner = isInnerTablePage();

  if (isInner) {
    return DATE_STEP_INNER;
  } else {
    return DATE_STEP_BY_TARIFF({ isTrends, isProduct })?.[tariffName];
  }
};

const checkDate = ({ date, tariffName, isTrends, isProduct, daysRange }) => {
  if (!date) return false;

  let step = getStep({ daysRange, isTrends, isProduct, tariffName });

  const startDate = moment(date[0]).startOf('day');
  const endDate = moment(date[1]).startOf('day');

  const stepCurrent = endDate.diff(startDate, 'days') + 1;

  const difference = moment(endDate).endOf('day').diff(moment(startDate).endOf('day'), 'days');

  const isStepValid = stepCurrent <= step;
  const isDifferenceValid = difference >= 0;

  let correctDateStart = date[0];
  let correctDateEnd = date[1];

  if (!isStepValid) {
    correctDateStart = getCorrectDateStart({ date, step });
    correctDateEnd = getCorrectDateEnd({ date, step });
  }

  if (!isDifferenceValid) {
    correctDateStart = date[1];
    correctDateEnd = date[0];
  }

  return {
    isValid: isStepValid && isDifferenceValid,
    correctDateStart,
    correctDateEnd,
  };
};

const getRangePickerLimits = ({ tariffName, startDate, daysRange }) => {
  const [DISABLED_DAYS_BEFORE, DISABLED_DAYS_AFTER] = getDateBeforeAfter({ tariffName });

  const minStartDate =
    window.location.pathname.includes(PAGE_SEO_MONITORING) ||
    window.location.pathname.includes(PAGE_SEO_BY_PHRASE)
      ? moment().subtract(90, 'days').startOf('day').toDate()
      : DISABLED_DAYS_BEFORE;
  const maxStartDate = DISABLED_DAYS_AFTER;

  const minEndDate = DISABLED_DAYS_BEFORE;
  const rangeEndDate = moment(startDate).toDate();
  if (daysRange) {
    rangeEndDate.setDate(rangeEndDate.getDate() + daysRange);
  }
  const maxEndDate = DISABLED_DAYS_AFTER;
  return {
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate: !daysRange || rangeEndDate > maxEndDate ? maxEndDate : rangeEndDate,
  };
};

const getDateRewrite = ({ date, tariffName, nextLink, prevLink, isInner = false }) => {
  const isTrends = nextLink === PAGE_TRENDS;
  const wasTrends = prevLink.includes(PAGE_TRENDS);

  const isDateCorrectByTariff = checkDateRange({ date, tariffName, isInner });

  // для страницы трендов надо изменять диапозон даты
  // при переходе со страницы тренды надо изменять дату на дефолтную
  // при переходе на внутрненнюю аналитику тоже надо менять дату на дефолтную
  // также надо откорретикровать дату, если она не соответсвует тарифу
  if (isTrends || !isDateCorrectByTariff || wasTrends) {
    return getDateInitial({ tariffName, isTrends, isInner });
  }

  return date;
};

const getNewAdvertisingRewrite = ({ value, nextLink }) => {
  if (isPageWithNewAdvertising(nextLink)) {
    return value;
  }
  return false;
};

const getWarehouseInitial = ({ platform, productPlatformId, warehouseType }) => {
  if (isProductPage() || isSeoByPhrasePage()) {
    return getWarehouseFromType({ type: warehouseType, platform: productPlatformId });
  }

  return isOzonPlatform(platform) ? [WAREHOUSE_FBO_OZON] : [WAREHOUSE_FBO_WB];
};

// check if platform is available for user
const isPlatformAvailable = ({ platformId, platforms }) => {
  const platformIds = platforms.map((platform) => +platform?.id);

  return platformIds.includes(+platformId);
};

const fixFilterPlatform = ({ params, platforms, productPlatformId }) => {
  const isProduct = isProductPage();

  let platformId = isProduct ? productPlatformId : params?.platform;

  if (Array.isArray(platformId)) {
    platformId = platformId[0];
  }

  if (platformId && !isPlatformAvailable({ platformId, platforms })) {
    params.platform = `${platforms?.[0]}`;
  } else {
    params.platform = platformId;
  }

  return params;
};

const getWarehouseType = (warehouse) => {
  if (!warehouse) return null;

  if (warehouse.length === 1) {
    warehouse = warehouse[0];
    if (+warehouse === +WAREHOUSE_FBO_WB || +warehouse === +WAREHOUSE_FBO_OZON) {
      return WAREHOUSE_TYPE_FBO;
    }

    if (+warehouse === +WAREHOUSE_FBS_WB || +warehouse === +WAREHOUSE_FBS_OZON) {
      return WAREHOUSE_TYPE_FBS;
    }
  }

  return null;
};

const getWarehouseFromType = ({ type, platform }) => {
  if (!type) return isOzonPlatform(platform) ? [12] : [];

  if (type === WAREHOUSE_TYPE_FBO) {
    return isOzonPlatform(platform) ? [WAREHOUSE_FBO_OZON] : [WAREHOUSE_FBO_WB];
  }

  if (type === WAREHOUSE_TYPE_FBS) {
    return isOzonPlatform(platform) ? [WAREHOUSE_FBS_OZON] : [WAREHOUSE_FBS_WB];
  }

  return [];
};

const fixFilterWarehouse = ({ params, productPlatformId }) => {
  let warehouse = params.warehouse;
  if (!warehouse) {
    warehouse = getWarehouseInitial({
      platform: params.platform,
      productPlatformId,
      warehouseType: params.warehouseType,
    });
  }

  params.warehouse = warehouse;

  if (!params.warehouseType) {
    params.warehouseType = getWarehouseType(warehouse);
  }

  return params;
};

const fixFilterHasAdvertising = ({ params }) => {
  if (isOzonPlatform(params.platform)) {
    params.hasAdvertising = 0;
  }

  return params;
};

const fixFilterSellers = ({ params, isSellersAvailable }) => {
  if (!isSellersAvailable) {
    params = { ...params, seller: [] };
  }

  return params;
};

const fixDate = ({ params, tariffName, isInner = false }) => {
  const isTrends = isTrendsPage();

  const isDateCorrect = checkDateRange({ date: params.date, tariffName });

  const initialDate = getDateInitial({ tariffName, isTrends, isInner });

  if (!isDateCorrect) {
    params.date = initialDate;
  }

  return params;
};

const fixPeriod = ({ params }) => {
  if (!params.period) {
    params.period = getPeriod(params.date);
  }

  return params;
};

const fixSortOrder = ({ params }) => {
  if (!params?.[F.SORT]) {
    params[F.SORT] = getSortInitial();
  }

  if (!params?.[F.ORDER]) {
    params[F.ORDER] = 'desc';
  }

  return params;
};

const fixFilterParams = ({
  params,
  platforms,
  isSellersAvailable,
  tariffName,
  productPlatformId,
  isInner = false,
}) => {
  fixFilterPlatform({ params, platforms, productPlatformId });
  fixFilterWarehouse({ params, productPlatformId });
  fixFilterHasAdvertising({ params });
  fixFilterSellers({ params, isSellersAvailable });
  fixDate({ params, tariffName, isInner });
  fixPeriod({ params });
  fixSortOrder({ params });

  return params;
};

const fixInnerFilterParams = ({ params }) => {
  fixDate({ params, isInner: true });
  fixSortOrder({ params });

  return params;
};

const getChipsFromParams = (params, chipsKeys) => {
  const chips = {};

  for (let i = 0; i < chipsKeys.length; i++) {
    const key = chipsKeys[i];

    if (params[key]) {
      chips[key] = params[key].map((key) => ({ key }));
    }
  }

  return chips;
};

const getChipsInitial = (chipsKeys) => {
  return chipsKeys.reduce((cur, key) => {
    return Object.assign(cur, { [key]: [] });
  }, {});
};

const getSortInitialByPath = (path) => {
  const isSeo = path.indexOf(PAGE_SEO) >= 0;
  const isSales = path.indexOf(PAGE_SALES) >= 0;
  const isSellers = path.indexOf(PAGE_SELLERS) >= 0;
  const isProducts = path.indexOf(PAGE_PRODUCTS) >= 0;
  const isBrands = path.indexOf(PAGE_BRANDS) >= 0;
  const isCategories = path.indexOf(PAGE_MAIN) >= 0;
  const isWarehouses = path.indexOf(PAGE_WAREHOUSES) >= 0;
  const isPriceSegment = path.indexOf(PAGE_PRICE_SEGMENTS) >= 0;
  const isTrends = path.indexOf(PAGE_TRENDS) >= 0;
  const isInnerProducts =
    path.indexOf(PAGE_INNER_PRODUCTS) >= 0 || path.indexOf(PAGE_API_KEYS) >= 0;
  const isInnerPricesTab = path.indexOf(PAGE_INNER_PRICES_TAB) >= 0;
  const isInnerDays = path.indexOf(PAGE_INNER_IN_DAYS_2) >= 0;
  const isInnerWeeks = path.indexOf(PAGE_INNER_WEEKS) >= 0;
  const isInnerWarehouses = window.location.pathname.includes(PAGE_PRODUCT_END_STOCKS);
  const isInnerSales = path.indexOf(PAGE_INNER_SALES) >= 0;
  const isInnerProduct = path.indexOf(PAGE_INNER_PRODUCT) >= 0;
  const isInnerProductOrdersSales = path.includes(PAGE_PRODUCT_END_ORDERS_SALES);
  const isProduct = path.startsWith(PAGE_PRODUCT);
  const isProductWarehouses = isProduct && path.includes(PAGE_PRODUCT_END_STOCKS);
  const isProductSizes = isProduct && path.includes(PAGE_PRODUCT_END_SIZES);
  const isProductColors = isProduct && path.includes(PAGE_PRODUCT_END_COLORS);
  const isProductSearch = isProduct && path.includes(PAGE_PRODUCT_END_SEARCH);
  const isInnerProductWarehouses = isInnerProduct && path.includes(PAGE_PRODUCT_END_STOCKS);
  const isDelivery = path.includes(PAGE_INNER_DELIVERY_TAB);
  const isInnerProductSizes = isInnerProduct && path.includes(PAGE_PRODUCT_END_SIZES);

  if (isDelivery) {
    return COLUMNS.CALC_QUANTITY;
  }

  if (isSeo) {
    return COLUMNS.FREQUENCY_WB;
  }

  if (isSales) {
    return COLUMNS.PERIOD;
  }

  if (isPriceSegment) {
    return COLUMNS.SIMILAR_NAME;
  }

  if (isTrends) {
    return 'name';
  }

  if (isInnerWarehouses || isInnerProductWarehouses) {
    return COLUMNS.WAREHOUSES__ORDERS_RUB;
  }
  if (isInnerProductSizes) {
    return 'size_orders_rub';
  }

  if (isProductWarehouses || isProductSizes || isProductColors || isWarehouses) {
    return 'orders_rub';
  }

  if (isProductSearch) {
    return PRODUCT_SORT[TABLE_TYPE.PRODUCT_SEARCH];
  }

  if (isProduct) {
    return COLUMNS.DATE;
  }

  if (isInnerWeeks) {
    return 'date_from';
  }

  if (isInnerProductOrdersSales) {
    return 'order_dt';
  }

  if (isInnerProduct) {
    return COLUMNS.DATE;
  }

  if (isInnerProducts) {
    return 'sum_orders_by_period_rub';
  }

  if (isInnerDays) {
    return COLUMNS.DATE;
  }

  if (isInnerSales) {
    return COLUMNS.ORDER_DT;
  }

  if (isInnerPricesTab) {
    return COLUMNS.PRICE;
  }

  if (isBrands || isProducts || isSellers || isCategories) {
    return 'orders_rub';
  }

  return COLUMNS.ORDERS_RUB;
};

const getSortInitial = (filter) => {
  return getSortInitialByPath(window.location.pathname, filter);
};

const getNestedSortInitial = () => {
  if (isSeoPage()) {
    return COLUMNS.FREQUENCY_WB;
  }

  return null;
};

const getDateDuration = (date) =>
  moment.duration(moment(date[1], DATE_FORMAT).diff(moment(date[0], DATE_FORMAT))).asDays() + 1;

const getPeriodByDate = (date) => {
  const duration = getDateDuration(date);

  if (duration <= 30) {
    return PERIOD_DAY;
  } else {
    return PERIOD_WEEK;
  }
};

const getPeriodByPath = ({ date, period, path }) => {
  const isGrowth = path.indexOf(PAGE_GROWTH) >= 0;
  const isTrends = path.indexOf(PAGE_TRENDS) >= 0;

  if (isGrowth) {
    return period && TABLE_HEADER_AVAILABLE_PERIODS.includes(period) ? period : PERIOD_WEEK;
  }

  if (isTrends) {
    return getPeriodByDate(date);
  }

  return null;
};

const getPeriodRewrite = ({ date, period, tariffName, nextLink, prevLink }) => {
  const dateRewrite = getDateRewrite({
    date,
    tariffName,
    nextLink,
    prevLink,
  });
  return getPeriodByPath({ path: nextLink, date: dateRewrite, period });
};

const getPeriod = (date) => {
  if (!date) return null;

  return getPeriodByPath({ path: window.location.pathname, date });
};

export {
  getInitialPlatform,
  getDateInitial,
  getWarehouseInitial,
  isPlatformAvailable,
  fixFilterParams,
  getChipsFromParams,
  isOzonPlatform,
  isWbPlatform,
  getDateRewrite,
  getNewAdvertisingRewrite,
  getSortInitial,
  getSortInitialByPath,
  getDateInitialProduct,
  getTariffRange,
  getDisabledDaysBefore,
  getDisabledDaysAfter,
  checkDate,
  DATE_FORMAT,
  WAREHOUSE_FBO_WB,
  WAREHOUSE_FBO_OZON,
  WAREHOUSE_FBS_WB,
  WAREHOUSE_FBS_OZON,
  WAREHOUSE_TYPE_FBS,
  WAREHOUSE_TYPE_FBO,
  PLATFORM_OZON,
  PLATFORM_WB,
  DEFAULT_PLATFORMS,
  getRangePickerLimits,
  INITIAL_DATE,
  getWarehouseType,
  getPeriod,
  getPeriodByPath,
  getPeriodRewrite,
  getNestedSortInitial,
  DATE_RANGE_BY_TARIFF,
  fixDate,
  getChipsInitial,
  fixInnerFilterParams,
};
